import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import GalleryComponent from "../components/Gallery/Gallery";
import BeforeAfter from "../components/Gallery/BeforeAfter";
import BaseLayout from "../components/global/BaseLayout";

function Gallery() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Gallery">
      <div className="w-full">
        <TransparentHeader
          headertitle="Gallery"
          Subheader="Gallery"
          bgimg={`${rpdata?.gallery?.[87]}`}
        />
        {
          rpdata?.beforeAfterGall?.length > 0 ?
            <BeforeAfter />
            : null
        }
        <div className="pb-32">
          <div className="text-center py-5">
            <h2 className="text-center pt-[100px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
          </div>
          <GalleryComponent
            galleryImages={
              rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock
            }
          />
        </div>
      </div>
    </BaseLayout>
  );
}

export default Gallery;
